<template>
    <div>
        <el-row class="cm-box-title">
            <el-col :span="12" class="fs-14"><i class="el-icon-tickets mr-5"></i>修改密码</el-col>
            <el-col :span="12" class="txt-right fs-12"><span class="c-red">*</span>为必填项</el-col>
        </el-row>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm mt-20">
            <div class="form-box-small">
                <el-form-item label="原密码" prop="old_password">
                    <el-input v-model="ruleForm.old_password" placeholder="原登录密码"></el-input>
                </el-form-item>
                <el-form-item label="新密码" prop="new_password">
                    <el-input v-model="ruleForm.new_password" placeholder="新的登录密码"></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="confirm_password">
                    <el-input v-model="ruleForm.confirm_password" placeholder="确认新密码"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-circle-check" @click="submitForm('ruleForm')" v-bind:loading="isLoading">提交</el-button>
                    <el-button icon="el-icon-edit" @click="resetForm('ruleForm')">重置</el-button>
                </el-form-item>
            </div>
        </el-form>
    </div>
</template>

<script>
    import User from '../../services/User.js'

    export default {
        name: "ResetPassword",
        data() {
            const validatePass = (rule, value, callback) => {
                if (value != this.ruleForm.new_password) {
                    callback(new Error('两次输入密码不一致!'));
                } else {
                    callback();
                }
            };
            return {
                ruleForm: {
                    old_password: '',
                    new_password: '',
                    confirm_password: ''
                },
                rules: {
                    old_password: [
                        {required: true, message: '请输入旧密码', trigger: 'blur'}
                    ],
                    new_password: [
                        {required: true, message: '请输入新密码', trigger: 'blur'}
                    ],
                    confirm_password: [
                        {required: true, message: '请确认新密码', trigger: 'blur'},
                        {validator: validatePass, trigger: 'blur'}
                    ]
                },
                isLoading: false
            }
        },
        methods: {
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.isLoading = true
                        this.$api.user.resetPassword(this.ruleForm).then(result => {
                            if (result.errCode) {
                                return this.$message.error(result.message)
                            }
                            this.$message.success('密码修改成功,请使用新密码重新登录')
                            User.logout(() => {
                                this.$router.push('/login')
                            })
                        }).catch(error => {
                            this.$message.error(error)
                        }).finally(() => {
                            this.isLoading = false
                        })
                    } else {
                        return false;
                    }
                });
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            }
        }
    }
</script>

<style scoped>
    .form-box-small {
        padding-top: 80px;
        padding-bottom: 50px;
    }
</style>